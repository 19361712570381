// --------------------------------------------------------------------------------------------------------------------
// 1. Include functions first (so you can manipulate colors, SVGs, calc, etc)
// --------------------------------------------------------------------------------------------------------------------

@import "~bootstrap/scss/functions";

// --------------------------------------------------------------------------------------------------------------------
// 2. Include any default variable overrides here
// --------------------------------------------------------------------------------------------------------------------

$info: #17a2b8;
$font-size-base: 0.875rem;
//$headings-font-weight: $font-weight-normal;
//$enable-gradients: true;
//$enable-shadows: true;

// --------------------------------------------------------------------------------------------------------------------
// 3. Include remainder of required Bootstrap stylesheets
// --------------------------------------------------------------------------------------------------------------------

@import "~bootstrap/scss/variables";

// --------------------------------------------------------------------------------------------------------------------
// 4. Include any default map overrides here
// --------------------------------------------------------------------------------------------------------------------

$custom-colors: (
	"sequoia-green": #014538,
	"ontarget-blue": #337ab7
);
$theme-colors: map-merge($theme-colors, $custom-colors);

// --------------------------------------------------------------------------------------------------------------------
// 5. Include remainder of required parts
// --------------------------------------------------------------------------------------------------------------------

@import "~bootstrap/scss/maps";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/root";

// --------------------------------------------------------------------------------------------------------------------
// 6. Optionally include any other parts as needed
// --------------------------------------------------------------------------------------------------------------------

@import "~bootstrap/scss/utilities";
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/type";
@import "~bootstrap/scss/images";
@import "~bootstrap/scss/containers";
@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/tables";
@import "~bootstrap/scss/forms";
@import "~bootstrap/scss/buttons";
@import "~bootstrap/scss/transitions";
@import "~bootstrap/scss/dropdown";
@import "~bootstrap/scss/button-group";
@import "~bootstrap/scss/nav";
@import "~bootstrap/scss/navbar";
@import "~bootstrap/scss/card";
@import "~bootstrap/scss/accordion";
@import "~bootstrap/scss/breadcrumb";
@import "~bootstrap/scss/pagination";
@import "~bootstrap/scss/badge";
@import "~bootstrap/scss/alert";
@import "~bootstrap/scss/progress";
@import "~bootstrap/scss/list-group";
@import "~bootstrap/scss/close";
@import "~bootstrap/scss/toasts";
@import "~bootstrap/scss/modal";
@import "~bootstrap/scss/tooltip";
@import "~bootstrap/scss/popover";
@import "~bootstrap/scss/carousel";
@import "~bootstrap/scss/spinners";
@import "~bootstrap/scss/offcanvas";
@import "~bootstrap/scss/placeholders";
@import "~bootstrap/scss/helpers";

// --------------------------------------------------------------------------------------------------------------------
// 7. Optionally include utilities API last to generate classes based on the Sass map in `_utilities.scss`
// --------------------------------------------------------------------------------------------------------------------

@import "~bootstrap/scss/utilities/api";

// --------------------------------------------------------------------------------------------------------------------
// 8. Add additional custom code here
// --------------------------------------------------------------------------------------------------------------------

// Bootstrap style overrides
.breadcrumb {
	font-size: 75%;
}

footer, .footer {
	font-size: 85%;
	height: 50px;
}
